import "./Header.css";

import logo from "../../icast_logo.png";

const Header = () => {
  return (
    <header className="header">
      <a class="navbar-brand" href="/">
        <img
          src={logo}
          className="img-responsive pe-2"
          height="40"
          alt="ICAST"
        />
        <span class="border-start border-2 ps-2 text-center icast__logo-span1">
          MF IRA BIL Calculator
        </span>
      </a>
    </header>
  );
};

export default Header;
