import styles from "./CaseNavBar.module.css"

const CaseNavBar = ({ setSelectedCase, selectedCase }) => {
    const queryParams = new URLSearchParams(window.location.search)

    return (
        <div className={queryParams.get("state") === 'embedded' ? styles.containerEmbedded : styles.container}>
            <div className={styles.navBar}>
                <h4 className={styles.heading}>Select Your Option:</h4>
                <div className={styles.tabs}>
                    <button
                        className={selectedCase === 'solar' ? styles.tabButtonActive : styles.tabButton}
                        onClick={() => {
                            setSelectedCase('solar')
                        }}
                    >
                        Solar PV
                    </button>
                    <button
                        className={selectedCase === 'ee' ? styles.tabButtonActive : styles.tabButton}
                        onClick={() => {
                            setSelectedCase('ee')
                        }}
                    >
                        Energy Efficiency
                    </button>
                    <button
                        className={selectedCase === 'ev' ? styles.tabButtonActive : styles.tabButton}
                        onClick={() => {
                            setSelectedCase('ev')
                            // window.scrollTo(0)
                        }}
                    >
                        EV Chargers
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CaseNavBar
