import { useState } from 'react'
import styles from './Inputs.module.css'

const InputField = ({ type, value, onChange, placeholder, required, labelText, disabled, direction, validation, onBlur }) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    return (
        <div className={styles.mainContainer}>
            <div className={direction === 'column' ? styles.containerColumn : direction === 'row' ? styles.container : styles.container}>
                <label className={styles.label}>
                    {labelText}
                </label>
                <div className={styles.input}>
                    <input
                        className={showAlert ? styles.invalidField : styles.field}
                        type={type}
                        value={value}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange(e)
                            if (validation && !onBlur) {
                                let { valid, message } = validation(e.target.value)

                                if (valid) {
                                    setShowAlert(false)
                                    setAlertMessage("")
                                }
                                else {
                                    setShowAlert(true)
                                    setAlertMessage(message)
                                }
                            }
                        }}
                        required={required}
                        disabled={disabled}
                        onBlur={(e) => {
                            if (onBlur) {
                                let { valid, message } = validation(e.target.value)

                                if (valid) {
                                    setShowAlert(false)
                                    setAlertMessage("")
                                }
                                else {
                                    setShowAlert(true)
                                    setAlertMessage(message)
                                }
                            }
                        }}
                    />
                </div>
            </div>
            {showAlert && (
                <p className={styles.alertText}>
                    {alertMessage}
                </p>
            )}
        </div>
    )
}

export default InputField