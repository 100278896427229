import "./App.css"

import Header from "./components/Header/Header"
import Cases from "./components/Cases/Cases"

function App() {
    const queryParams = new URLSearchParams(window.location.search)

    return (
        <div className="app-container">
            {queryParams.get("state") !== 'embedded' && (
                <Header />
            )}
            <Cases />
        </div>
    )
}

export default App