import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export const useSendEmail = (name, email, subject, body) => {
  let [sendEmail, setSendEmail] = useState(false);
  let [response, setResponse] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (sendEmail) {
      callApi();
    }
  }, [sendEmail]);

  const callApi = async () => {
    setIsLoading(true);

    try {
      let response = await axios.post("https://bee.icastusa.org/api/v2/forms", {
        name,
        email,
        subject,
        body,
      });

      setResponse(response);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error);
      setResponse(null);
    } finally {
      setIsLoading(false);
      setSendEmail(false);
    }
  };

  return {
    setSendEmail,
    response,
    isLoading,
    errorMessage,
    setResponse,
  };
};
