import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'

import styles from './Modals.module.css'

import ModalInputField from './ModalInputs/ModalInputField'
import { useSendEmail } from '../../Apis/apis'
import { validateEmail } from '../../Helpers/Validators'

const Contact = ({ selectedCase, inputs }) => {
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const [showModal, setShowModal] = useState(false)

    let body = `Company: ${company}\nPhone: ${phone}\nComment: ${message}`

    const { errorMessage, isLoading, response, setSendEmail, setResponse } = useSendEmail(name, email, "MF IRA BIL Calculator", body)

    useEffect(() => {
        if (response) {
            setTimeout(() => {
                setShowModal(false)
                refreshValues()
            }, 2000)
        }
    }, [response])

    const submitHandler = (e) => {
        if (validateEmail(email).valid && name !== '') {
            e.preventDefault()
            setSendEmail(true)
        }
    }

    function refreshValues() {
        setName("")
        setCompany("")
        setEmail("")
        setPhone("")
        setMessage("")
        setResponse(null)
    }

    return (
        <>
            <div className={styles.textContainer}>
                <p className={styles.text}>
                    These estimates are based on numerous assumptions. For a more accurate result
                    <button
                        className={styles.button}
                        onClick={() => {
                            setShowModal(true)
                        }}
                    >
                        Contact Us
                    </button>
                </p>
            </div>

            {/* MODAL */}
            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false)
                    refreshValues()
                }}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader closeButton>
                    <ModalTitle className={styles.heading}>Contact Us</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <form className={styles.fieldsContainer}>
                        {selectedCase && (
                            <ModalInputField
                                labelText="Selected Option:"
                                value={selectedCase}
                                disabled={true}
                                direction='column'
                            />
                        )}
                        {inputs?.map((input) => {
                            let { name, value } = input

                            return (
                                <ModalInputField
                                    labelText={name}
                                    value={value}
                                    disabled={true}
                                    direction='column'
                                    key={name}
                                />
                            )
                        })}
                        <ModalInputField
                            labelText="Name*"
                            type={"text"}
                            placeholder="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            direction='column'
                            required
                        />
                        <ModalInputField
                            labelText="Company"
                            type={"text"}
                            placeholder="Company"
                            value={company}
                            onChange={(e) => {
                                setCompany(e.target.value)
                            }}
                            direction='column'
                        />
                        <ModalInputField
                            labelText="Email*"
                            type={"email"}
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            direction='column'
                            required
                            validation={validateEmail}
                        />
                        <ModalInputField
                            labelText="Phone"
                            type={"number"}
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value)
                            }}
                            direction='column'
                        />
                        <ModalInputField
                            labelText="Comment on what you would like to discuss"
                            type={"text"}
                            placeholder="Type your comment here"
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }}
                            direction='column'
                            isTextarea
                        />
                        <button
                            type="submit"
                            style={{
                                'width': '30%',
                                'marginTop': '20px'
                            }}
                            className="btn btn-primary"
                            onClick={(e) => {
                                submitHandler(e)
                            }}
                            disabled={response ? true : false}
                        >
                            {isLoading ? "Submitting" : response ? "Submitted" : "Submit"}
                        </button>
                        {errorMessage && (
                            <p style={{
                                color: 'red'
                            }}>
                                Error
                            </p>
                        )}
                        {response && (
                            <p style={{
                                color: 'green'
                            }}>
                                Thank you for contacting us
                            </p>
                        )}
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Contact
