export function validateEmail(mail) {
    return {
        "valid": /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail),
        "message": /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail) ? "" : "Invalid Email"
    }
}

export function validatePhone(phone) {
    return (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone))
}

export function validateOnlyNumbers(number) {
    return {
        "valid": /^[0-9]+$/.test(number) || number === '',
        "message": /^[0-9]+$/.test(number) ? "" : "Only numbers are allowed"
    }
}