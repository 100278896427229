import { useState, useEffect } from "react"

import styles from "./Cases.module.css"

import CaseNavBar from "./CaseNavBar/CaseNavBar"
import EnergyEfficiency from "../Cases/Tabs/EnergyEfficiency"
import EVChargers from "../Cases/Tabs/EVChargers"
import SolarPV from "../Cases/Tabs/SolarPV"

const Cases = () => {
    // Cases can be 'ee', 'ev', 'solar'
    const [selectedCase, setSelectedCase] = useState("solar")

    useEffect(() => {
        localStorage.clear()
    }, [])

    const queryParams = new URLSearchParams(window.location.search)

    return (
        <div>
            <CaseNavBar
                selectedCase={selectedCase}
                setSelectedCase={setSelectedCase}
            />
            <div className={queryParams.get("state") === 'embedded' ? styles.containerEmbedded : styles.container}>
                <main className={styles.subContainer}>
                    {selectedCase === "ee" && <EnergyEfficiency />}
                    {selectedCase === "ev" && <EVChargers />}
                    {selectedCase === "solar" && <SolarPV />}
                </main>
            </div>
        </div>
    )
}

export default Cases