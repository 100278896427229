import styles from './Inputs.module.css'

const DropdownField = ({ value, onChange, required, labelText, options }) => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <label className={styles.label}>
                    {labelText}
                </label>
                <div className={styles.input}>
                    <select
                        value={value}
                        className="form-select"
                        style={{

                        }}
                        // aria-label=".form-select-lg example"
                        onChange={onChange}
                        required={required}
                    >
                        {options.map((option) => {
                            let { value, name } = option

                            return (
                                <option value={value} className={styles.text} key={value}>
                                    {name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default DropdownField
