import { useEffect, useState } from "react"

import "./tabs.css"
import styles from "./tabs.module.css"

import InputField from "../../Inputs/InputField"
import DropdownField from "../../Inputs/DropdownField"
import Contact from "../../Modals/Contact"
import { validateOnlyNumbers } from "../../../Helpers/Validators"

const EE_Chargers = () => {
    const queryParams = new URLSearchParams(window.location.search)

    const [energyInput, setEnergyInput] = useState("")
    const [EEUpgrades, setEEUpgrades] = useState(0)
    const [energySavings, setEnergySavings] = useState(0)
    const [rehabTaxCredit9, setRehabTaxCredit9] = useState(0)
    const [rehabTaxCredit4, setRehabTaxCredit4] = useState(0)
    const [rehabSquareFoot, setRehabSquareFoot] = useState(0)
    const [rehabwapGrant, setRehabWapGrant] = useState(0)
    const [rehabUtilityRebate, setRehabUtilityRebate] = useState(0)
    const [rehabGrantProgram, setRehabGrantProgram] = useState(0)
    const [rehabSupportableDebt, setRehabSupportableDebt] = useState(0)
    const [newSquareFoot, setNewSquareFoot] = useState(0)
    const [rehabLIHTC9, setRehabLIHTC9] = useState(0)
    const [rehabLIHTC4, setRehabLIHTC4] = useState(0)
    const [rehabNoLIHTC, setRehabNoLIHTC] = useState(0)
    const [newLIHTC9, setNewLIHTC9] = useState(0)
    const [newLIHTC4, setNewLIHTC4] = useState(0)
    const [newNoLIHTC, setNewNoLIHTC] = useState(0)

    const [showAlert, setShowAlert] = useState(false)

    const [selectLIHTC, setSelectLIHTC] = useState("9")
    const [selectedTable, setSelectedTable] = useState("new")

    useEffect(() => {
        const energyInputValue = localStorage.getItem("energyInput")
        if (energyInputValue) {
            setEnergyInput(energyInputValue)
        }
    }, [])

    useEffect(() => {
        window.onbeforeunload = () => {
            localStorage.clear()
            setEnergyInput(0)
            setEEUpgrades(0)
            setEnergySavings(0)
            setRehabTaxCredit9(0)
            setRehabTaxCredit4(0)
            setRehabSquareFoot(0)
            setRehabWapGrant(0)
            setRehabUtilityRebate(0)
            setRehabGrantProgram(0)
            setRehabSupportableDebt(0)
            setNewSquareFoot(0)
            setRehabLIHTC9(0)
            setRehabLIHTC4(0)
            setRehabNoLIHTC(0)
            setNewLIHTC9(0)
            setNewLIHTC4(0)
            setNewNoLIHTC(0)
        }
    }, [])

    const handleLIHTCChange = (event) => {
        const optionValue = event.target.value
        setSelectLIHTC(optionValue)
    }

    const handleEnergyInputChange = (event) => {
        const Value = event.target.value
        setEnergyInput(Value)

        localStorage.setItem("energyInput", Value)
        if (Value && Value >= 0) {
            const ValueOfIncreementalEE = parseFloat(
                document
                    .getElementById("costOfIncreementalEEUpgrades")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const costOfEEUpgradesForProperty =
                energyInput * ValueOfIncreementalEE
            setEEUpgrades(costOfEEUpgradesForProperty)

            const ValueOfAnnualEnergySavings = parseFloat(
                document
                    .getElementById("costOfAnnualEnergySavings")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const UtilityRateForResidentialMeters = parseFloat(
                document
                    .getElementById("costOfUtilityRateForResidential")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfEnergySavings =
                energyInput *
                ValueOfAnnualEnergySavings *
                UtilityRateForResidentialMeters
            setEnergySavings(ValueOfEnergySavings)

            const ValueOfRehabTaxCredit =
                parseFloat(
                    document.getElementById("costOfEnergyTaxCredits")
                        .textContent
                ) / 100
            const ValueOfEnergyLIHTC9 =
                parseFloat(
                    document.getElementById("costOfEnergyTaxCredits")
                        .textContent
                ) / 100
            const TaxCreditForRehab =
                costOfEEUpgradesForProperty *
                ValueOfRehabTaxCredit *
                ValueOfEnergyLIHTC9
            setRehabTaxCredit9(TaxCreditForRehab)

            const ValueOfEnergyLIHTC4 =
                parseFloat(
                    document.getElementById("costOfEnergyLIHTC4").textContent
                ) / 100
            const taxCreditRehabLIHTC4 =
                costOfEEUpgradesForProperty *
                ValueOfRehabTaxCredit *
                ValueOfEnergyLIHTC4
            setRehabTaxCredit4(taxCreditRehabLIHTC4)

            const ValueOfTaxDeduction = parseFloat(
                document
                    .getElementById("costOfTaxDeduction")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfAvgSizeApart = parseFloat(
                document
                    .getElementById("costOfAverageSizeApart")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfSquareFoot =
                energyInput *
                ValueOfTaxDeduction *
                ValueOfAvgSizeApart *
                ValueOfRehabTaxCredit
            setRehabSquareFoot(ValueOfSquareFoot)

            const ValueOfWapIncentive = parseFloat(
                document
                    .getElementById("costOfWapIncentive")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfRehabWapGrant = energyInput * ValueOfWapIncentive
            setRehabWapGrant(ValueOfRehabWapGrant)

            const ValueOfEnergyUtilityRebate = parseFloat(
                document
                    .getElementById("costOfEnergyUtilityRebate")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const RehabUtilityRebate = energyInput * ValueOfEnergyUtilityRebate
            setRehabUtilityRebate(RehabUtilityRebate)

            const ValueOfEnergyIRAIncentive = parseFloat(
                document
                    .getElementById("costOfEnergyIRAIncentive")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfRehabIRAGrant = energyInput * ValueOfEnergyIRAIncentive
            setRehabGrantProgram(ValueOfRehabIRAGrant)

            const ValueOfEnergyDSCR = parseFloat(
                document
                    .getElementById("costOfEnergyDSCR")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfEnergyDebtConstant =
                parseFloat(
                    document
                        .getElementById("costOfEnergyDebtConstant")
                        .textContent.replace("$", "")
                        .replace(",", "")
                ) / 100
            const ValueOfRehabSupportableDebt =
                ValueOfEnergySavings /
                ValueOfEnergyDSCR /
                ValueOfEnergyDebtConstant
            setRehabSupportableDebt(ValueOfRehabSupportableDebt)

            const ValueOfNewEnergyTaxCredits = parseFloat(
                document
                    .getElementById("costOfNewTaxCredit")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfNewSquareFoot =
                energyInput * ValueOfRehabTaxCredit * ValueOfNewEnergyTaxCredits
            setNewSquareFoot(ValueOfNewSquareFoot)

            const TotalOfRehabLIHTC9 =
                TaxCreditForRehab +
                ValueOfSquareFoot +
                ValueOfRehabWapGrant +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setRehabLIHTC9(TotalOfRehabLIHTC9)

            const TotalOfRehabLIHTC4 =
                taxCreditRehabLIHTC4 +
                ValueOfSquareFoot +
                ValueOfRehabWapGrant +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setRehabLIHTC4(TotalOfRehabLIHTC4)

            const TotalOfRehabNoLIHTC =
                0 +
                ValueOfSquareFoot +
                ValueOfRehabWapGrant +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setRehabNoLIHTC(TotalOfRehabNoLIHTC)

            const TotalOfNewLIHTC9 =
                TaxCreditForRehab +
                ValueOfNewSquareFoot +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setNewLIHTC9(TotalOfNewLIHTC9)

            const TotalOfNewLIHTC4 =
                taxCreditRehabLIHTC4 +
                ValueOfNewSquareFoot +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setNewLIHTC4(TotalOfNewLIHTC4)

            const TotalOfNewNoLIHTC =
                0 +
                ValueOfNewSquareFoot +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setNewNoLIHTC(TotalOfNewNoLIHTC)
        } else {
            setEEUpgrades(0)
            setEnergySavings(0)
            setRehabTaxCredit9(0)
            setRehabTaxCredit4(0)
            setRehabSquareFoot(0)
            setRehabWapGrant(0)
            setRehabUtilityRebate(0)
            setRehabGrantProgram(0)
            setRehabSupportableDebt(0)
            setNewSquareFoot(0)
            setRehabLIHTC9(0)
            setRehabLIHTC4(0)
            setRehabNoLIHTC(0)
            setNewLIHTC9(0)
            setNewLIHTC4(0)
            setNewNoLIHTC(0)
        }
    }

    useEffect(() => {
        if (energyInput === "") {
            setEEUpgrades(0)
            setEnergySavings(0)
            setRehabTaxCredit9(0)
            setRehabTaxCredit4(0)
            setRehabSquareFoot(0)
            setRehabWapGrant(0)
            setRehabUtilityRebate(0)
            setRehabGrantProgram(0)
            setRehabSupportableDebt(0)
            setNewSquareFoot(0)
            setRehabLIHTC9(0)
            setRehabLIHTC4(0)
            setRehabNoLIHTC(0)
            setNewLIHTC9(0)
            setNewLIHTC4(0)
            setNewNoLIHTC(0)
        } else if (energyInput >= 0) {
            const ValueOfIncreementalEE = parseFloat(
                document
                    .getElementById("costOfIncreementalEEUpgrades")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const costOfEEUpgradesForProperty =
                energyInput * ValueOfIncreementalEE
            setEEUpgrades(costOfEEUpgradesForProperty)

            const ValueOfAnnualEnergySavings = parseFloat(
                document
                    .getElementById("costOfAnnualEnergySavings")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const UtilityRateForResidentialMeters = parseFloat(
                document
                    .getElementById("costOfUtilityRateForResidential")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfEnergySavings =
                energyInput *
                ValueOfAnnualEnergySavings *
                UtilityRateForResidentialMeters
            setEnergySavings(ValueOfEnergySavings)

            const ValueOfRehabTaxCredit =
                parseFloat(
                    document.getElementById("costOfEnergyTaxCredits")
                        .textContent
                ) / 100
            const ValueOfEnergyLIHTC9 =
                parseFloat(
                    document.getElementById("costOfEnergyTaxCredits")
                        .textContent
                ) / 100
            const TaxCreditForRehab =
                costOfEEUpgradesForProperty *
                ValueOfRehabTaxCredit *
                ValueOfEnergyLIHTC9
            setRehabTaxCredit9(TaxCreditForRehab)

            const ValueOfEnergyLIHTC4 =
                parseFloat(
                    document.getElementById("costOfEnergyLIHTC4").textContent
                ) / 100
            const taxCreditRehabLIHTC4 =
                costOfEEUpgradesForProperty *
                ValueOfRehabTaxCredit *
                ValueOfEnergyLIHTC4
            setRehabTaxCredit4(taxCreditRehabLIHTC4)

            const ValueOfTaxDeduction = parseFloat(
                document
                    .getElementById("costOfTaxDeduction")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfAvgSizeApart = parseFloat(
                document
                    .getElementById("costOfAverageSizeApart")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfSquareFoot =
                energyInput *
                ValueOfTaxDeduction *
                ValueOfAvgSizeApart *
                ValueOfRehabTaxCredit
            setRehabSquareFoot(ValueOfSquareFoot)

            const ValueOfWapIncentive = parseFloat(
                document
                    .getElementById("costOfWapIncentive")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfRehabWapGrant = energyInput * ValueOfWapIncentive
            setRehabWapGrant(ValueOfRehabWapGrant)

            const ValueOfEnergyUtilityRebate = parseFloat(
                document
                    .getElementById("costOfEnergyUtilityRebate")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const RehabUtilityRebate = energyInput * ValueOfEnergyUtilityRebate
            setRehabUtilityRebate(RehabUtilityRebate)

            const ValueOfEnergyIRAIncentive = parseFloat(
                document
                    .getElementById("costOfEnergyIRAIncentive")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfRehabIRAGrant = energyInput * ValueOfEnergyIRAIncentive
            setRehabGrantProgram(ValueOfRehabIRAGrant)

            const ValueOfEnergyDSCR = parseFloat(
                document
                    .getElementById("costOfEnergyDSCR")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfEnergyDebtConstant =
                parseFloat(
                    document
                        .getElementById("costOfEnergyDebtConstant")
                        .textContent.replace("$", "")
                        .replace(",", "")
                ) / 100
            const ValueOfRehabSupportableDebt =
                ValueOfEnergySavings /
                ValueOfEnergyDSCR /
                ValueOfEnergyDebtConstant
            setRehabSupportableDebt(ValueOfRehabSupportableDebt)

            const ValueOfNewEnergyTaxCredits = parseFloat(
                document
                    .getElementById("costOfNewTaxCredit")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const ValueOfNewSquareFoot =
                energyInput * ValueOfRehabTaxCredit * ValueOfNewEnergyTaxCredits
            setNewSquareFoot(ValueOfNewSquareFoot)

            const TotalOfRehabLIHTC9 =
                TaxCreditForRehab +
                ValueOfSquareFoot +
                ValueOfRehabWapGrant +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setRehabLIHTC9(TotalOfRehabLIHTC9)

            const TotalOfRehabLIHTC4 =
                taxCreditRehabLIHTC4 +
                ValueOfSquareFoot +
                ValueOfRehabWapGrant +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setRehabLIHTC4(TotalOfRehabLIHTC4)

            const TotalOfRehabNoLIHTC =
                0 +
                ValueOfSquareFoot +
                ValueOfRehabWapGrant +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setRehabNoLIHTC(TotalOfRehabNoLIHTC)

            const TotalOfNewLIHTC9 =
                TaxCreditForRehab +
                ValueOfNewSquareFoot +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setNewLIHTC9(TotalOfNewLIHTC9)

            const TotalOfNewLIHTC4 =
                taxCreditRehabLIHTC4 +
                ValueOfNewSquareFoot +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setNewLIHTC4(TotalOfNewLIHTC4)

            const TotalOfNewNoLIHTC =
                0 +
                ValueOfNewSquareFoot +
                RehabUtilityRebate +
                ValueOfRehabIRAGrant +
                ValueOfRehabSupportableDebt
            setNewNoLIHTC(TotalOfNewNoLIHTC)
        }
    }, [energyInput])

    return (
        <div className={styles.main}>
            <div className={styles.fieldsContainer}>
                <InputField
                    labelText="Number of Apartments on Property:"
                    type={"number"}
                    placeholder="Enter a value"
                    value={energyInput}
                    onChange={handleEnergyInputChange}
                    required
                    validation={validateOnlyNumbers}
                />
                <DropdownField
                    labelText="Rehab or New Construction:"
                    onChange={(e) => setSelectedTable(e.target.value)}
                    options={[
                        { value: "new", name: "New Construction" },
                        { value: "rehab", name: "Rehab" },
                    ]}
                    value={selectedTable}
                />
            </div>

            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                <table style={{ visibility: "collapse" }}>
                    <thead>
                        <tr>
                            <th>Assumptions</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Incremental Cost of EE Upgrades</td>
                            <td id="costOfIncreementalEEUpgrades">$10,000</td>
                        </tr>
                        <tr>
                            <td>WAP Incentive per Unit</td>
                            <td id="costOfWapIncentive">$8,000</td>
                        </tr>
                        <tr>
                            <td>Utility Rebate for EE per unit</td>
                            <td id="costOfEnergyUtilityRebate">$3,000</td>
                        </tr>
                        <tr>
                            <td>Annual Energy Savings Per Unit</td>
                            <td id="costOfAnnualEnergySavings">3000 kWh</td>
                        </tr>
                        <tr>
                            <td>Utility Rate for Residential Meters</td>
                            <td id="costOfUtilityRateForResidential">$0.12</td>
                        </tr>
                        <tr>
                            <td>SREC Value</td>
                            <td>$10</td>
                        </tr>
                        <tr>
                            <td>IRA incentive from HEEHR</td>
                            <td id="costOfEnergyIRAIncentive">$14,000</td>
                        </tr>
                        <tr>
                            <td>45L Tax Credit</td>
                            <td id="costOfNewTaxCredit">$5,000</td>
                        </tr>
                        <tr>
                            <td>179D Tax Deduction</td>
                            <td id="costOfTaxDeduction">$5.00</td>
                        </tr>
                        <tr>
                            <td>Avg. Size of Apartment</td>
                            <td id="costOfAverageSizeApart">750</td>
                        </tr>
                        <tr>
                            <td>Value of Tax Credits</td>
                            <td id="costOfEnergyTaxCredits">90%</td>
                        </tr>
                        <tr>
                            <td>Value of 9% LIHTC</td>
                            <td id="costOfEnergyLIHTC9">90%</td>
                        </tr>
                        <tr>
                            <td>Value of 4% LIHTC</td>
                            <td id="costOfEnergyLIHTC4">40%</td>
                        </tr>
                        <tr>
                            <td>Value of Depreciation</td>
                            <td>25%</td>
                        </tr>
                        <tr>
                            <td>Income Tax Rate</td>
                            <td>21%</td>
                        </tr>
                        <tr>
                            <td>DSCR</td>
                            <td id="costOfEnergyDSCR">1.15</td>
                        </tr>
                        <tr>
                            <td>Debt Constant</td>
                            <td id="costOfEnergyDebtConstant">6.50%</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {selectedTable === "new" && (
                <div className={styles.groupContainer}>
                    {/* CALCULATIONS */}
                    <div className={styles.group}>
                        <h3 className={styles.heading}>Calculations</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Cost of EE Upgrades for Property</td>
                                    <td>
                                        $
                                        {EEUpgrades.toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Value of Annual Energy Savings</td>
                                    <td>
                                        $
                                        {energySavings.toLocaleString(
                                            undefined,
                                            { maximumFractionDigits: 0 }
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* MFAH */}
                    <div className={styles.group}>
                        <h3 className={styles.heading}>
                            Potential Value of Benefits to Your MFAH Property*
                        </h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>At the Lower End</td>
                                    <td>
                                        $
                                        {(newNoLIHTC / 2).toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>At the Higher End</td>
                                    <td>
                                        $
                                        {newLIHTC9.toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* LIHTC */}
                    <div className={styles.group}>
                        <h3 className={styles.heading}>
                            Low-Income Housing Tax Credit (LIHTC)
                        </h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Select Option:</th>
                                    <th>
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            onChange={handleLIHTCChange}
                                            value={selectLIHTC || ""}
                                        >
                                            <option id="9" value="9">
                                                9% LIHTC
                                            </option>
                                            <option id="4" value="4">
                                                4% LIHTC
                                            </option>
                                            <option id="0" value="0">
                                                No LIHTC
                                            </option>
                                        </select>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Potential Total Value of Benefits to
                                        Your MFAH Property
                                    </td>
                                    {selectLIHTC === "9" && (
                                        <td>
                                            $
                                            {newLIHTC9.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )}
                                        </td>
                                    )}
                                    {selectLIHTC === "4" && (
                                        <td>
                                            $
                                            {newLIHTC4.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )}
                                        </td>
                                    )}
                                    {selectLIHTC === "0" && (
                                        <td>
                                            $
                                            {newNoLIHTC.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {selectedTable === "rehab" && (
                <div className={styles.groupContainer}>
                    {/* CALCULATIONS */}
                    <div className={styles.group}>
                        <h3 className={styles.heading}>Calculations</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Cost of EE Upgrades for Property</td>
                                    <td>
                                        $
                                        {EEUpgrades.toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Value of Annual Energy Savings</td>
                                    <td>
                                        $
                                        {energySavings.toLocaleString(
                                            undefined,
                                            { maximumFractionDigits: 0 }
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* MFAH */}
                    <div className={styles.group}>
                        <h3 className={styles.heading}>
                            Potential Value of Benefits to Your MFAH Property*
                        </h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>At the Lower End</td>
                                    <td>
                                        $
                                        {(rehabNoLIHTC / 2).toLocaleString(
                                            undefined,
                                            { maximumFractionDigits: 0 }
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>At the Higher End</td>
                                    <td>
                                        $
                                        {rehabLIHTC9.toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* LIHTC */}
                    <div className={styles.group}>
                        <h3 className={styles.heading}>
                            Low-Income Housing Tax Credit (LIHTC)
                        </h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Select Option:</th>
                                    <th>
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            onChange={handleLIHTCChange}
                                            value={selectLIHTC || ""}
                                        >
                                            <option id="9" value="9">
                                                9% LIHTC
                                            </option>
                                            <option id="4" value="4">
                                                4% LIHTC
                                            </option>
                                            <option id="0" value="0">
                                                No LIHTC
                                            </option>
                                        </select>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Potential Total Value of Benefits to
                                        Your MFAH Property
                                    </td>
                                    {selectLIHTC === "9" && (
                                        <td>
                                            $
                                            {rehabLIHTC9.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )}
                                        </td>
                                    )}
                                    {selectLIHTC === "4" && (
                                        <td>
                                            $
                                            {rehabLIHTC4.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )}
                                        </td>
                                    )}
                                    {selectLIHTC === "0" && (
                                        <td>
                                            $
                                            {rehabNoLIHTC.toLocaleString(
                                                undefined,
                                                { maximumFractionDigits: 0 }
                                            )}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {(selectedTable === "new" || selectedTable === "rehab") && (
                <>
                    {queryParams.get("state") !== 'embedded' && (
                        <Contact
                        // selectedCase={"Energy Efficiency"}
                        // inputs={[
                        //     {
                        //         'name': 'Number of Apartments on Property:',
                        //         'value': energyInput
                        //     },
                        //     {
                        //         'name': 'Rehab or New Construction:',
                        //         'value': selectedTable === 'new' ? "New Construction" : selectedTable === 'rehab' ? "Rehab" : ""
                        //     }
                        // ]}
                        />
                    )}
                </>
            )}

            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                {selectedTable === "rehab" && (
                    <table>
                        <thead>
                            <tr>
                                <th>Value of</th>
                                <th>9% LIHTC</th>
                                <th>4% LIHTC</th>
                                <th>No LIHTC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> Tax Credit Equity </td>
                                <td>
                                    $
                                    {rehabTaxCredit9.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}{" "}
                                </td>

                                <td>
                                    $
                                    {rehabTaxCredit4.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>$0 </td>
                            </tr>
                            <tr>
                                <td>179D @$5/sq ft.</td>
                                <td>
                                    $
                                    {rehabSquareFoot.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}{" "}
                                </td>
                                <td>
                                    $
                                    {rehabSquareFoot.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {rehabSquareFoot.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <td>WAP Grant</td>
                                <td>
                                    $
                                    {rehabwapGrant.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}{" "}
                                </td>
                                <td>
                                    $
                                    {rehabwapGrant.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {rehabwapGrant.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <td>Utility Rebates</td>
                                <td>
                                    $
                                    {rehabUtilityRebate.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabUtilityRebate.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabUtilityRebate.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>IRA Grant Program</td>
                                <td>
                                    $
                                    {rehabGrantProgram.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabGrantProgram.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabGrantProgram.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}{" "}
                                </td>
                            </tr>
                            <tr>
                                <td>Supportable Debt</td>
                                <td>
                                    $
                                    {rehabSupportableDebt.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabSupportableDebt.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabSupportableDebt.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>TOTAL</td>
                                <td>
                                    $
                                    {rehabLIHTC9.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {rehabLIHTC4.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {rehabNoLIHTC.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>

            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                {selectedTable === "new" && (
                    <table>
                        <thead>
                            <tr>
                                <th>Value of</th>
                                <th>9% LIHTC</th>
                                <th>4% LIHTC</th>
                                <th>No LIHTC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> Tax Credit Equity </td>
                                <td>
                                    $
                                    {rehabTaxCredit9.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}{" "}
                                </td>

                                <td>
                                    $
                                    {rehabTaxCredit4.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>$0 </td>
                            </tr>
                            <tr>
                                <td>45L @$5K/unit</td>
                                <td>
                                    $
                                    {newSquareFoot.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {newSquareFoot.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {newSquareFoot.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <td>Utility Rebates</td>
                                <td>
                                    $
                                    {rehabUtilityRebate.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabUtilityRebate.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabUtilityRebate.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>IRA Grant Program</td>
                                <td>
                                    $
                                    {rehabGrantProgram.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabGrantProgram.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabGrantProgram.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}{" "}
                                </td>
                            </tr>
                            <tr>
                                <td>Supportable Debt</td>
                                <td>
                                    $
                                    {rehabSupportableDebt.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabSupportableDebt.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                                <td>
                                    $
                                    {rehabSupportableDebt.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>TOTAL</td>
                                <td>
                                    $
                                    {newLIHTC9.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {newLIHTC4.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td>
                                    $
                                    {newNoLIHTC.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

export default EE_Chargers
