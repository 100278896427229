import { useEffect, useState } from "react"

import "./tabs.css"
import styles from "./tabs.module.css"

import InputField from "../../Inputs/InputField"
import Contact from "../../Modals/Contact"
import { validateOnlyNumbers } from "../../../Helpers/Validators"

const EV_Chargers = () => {
    const queryParams = new URLSearchParams(window.location.search)

    const [evInput, setEvInput] = useState("")
    const [evCost, setEvCost] = useState(0)
    const [amountEv, setAmountEv] = useState(0)
    const [lihtc4, setLihtc4] = useState(0)
    const [itc, setItc] = useState(0)
    const [marsCost, setmarsCost] = useState(0)
    const [utilityRebate, setUtilityRebate] = useState(0)
    const [iraIncentive, setIraIncentive] = useState(0)
    const [totalLIHTC9, setLIHTC9] = useState(0)
    const [totalLIHTC4, setLIHTC4] = useState(0)
    const [noLIHTC, setNoLIHTC] = useState(0)
    const [selectedLIHTC, setSelectedOption] = useState('9LIHTC')

    useEffect(() => {
        const savedValue = localStorage.getItem("evInput");
        if (savedValue !== null) {
            setEvInput(parseInt(savedValue));
        }
    }, []);


    useEffect(() => {
        window.onbeforeunload = () => {
            localStorage.clear();
            setEvInput(0);
            setEvCost(0);
            setAmountEv(0);
            setLihtc4(0);
            setItc(0);
            setmarsCost(0);
            setUtilityRebate(0);
            setIraIncentive(0);
            setLIHTC9(0);
            setNoLIHTC(0);
        };

    }, []);


    useEffect(() => {


        if (evInput === "") {
            setEvCost(0);
            setAmountEv(0);
            setLihtc4(0);
            setItc(0);
            setmarsCost(0);
            setUtilityRebate(0);
            setIraIncentive(0);
            setLIHTC9(0);
            setLIHTC4(0);
            setNoLIHTC(0);
        } else if (evInput >= 0) {
            const evChargerCost = parseFloat(
                document
                    .getElementById("costOfEvChargerCell")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const evTotalCost = evInput * evChargerCost
            setEvCost(evTotalCost)

            const costOfITC =
                parseFloat(document.getElementById("costOfITCRef").textContent) /
                100
            const costOfTaxCredit =
                parseFloat(document.getElementById("taxCreditRef").textContent) /
                100

            const TotalITC = evTotalCost * costOfITC * costOfTaxCredit
            setItc(TotalITC)

            const costOfIRAIncentive = parseFloat(
                document
                    .getElementById("iraIncentive")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const totalIRAIncentive = evInput * costOfIRAIncentive
            setIraIncentive(totalIRAIncentive)

            const amountOfTotalCost = evTotalCost * 0.9 * 0.85
            setAmountEv(amountOfTotalCost)
            const amountOfLIHTC4 = evTotalCost * 0.4 * 0.85
            setLihtc4(amountOfLIHTC4)
            const amountOfMarsDepreciation = evTotalCost * 0.2 * 0.2
            setmarsCost(amountOfMarsDepreciation)
            const amountOfUtilityRebate = evTotalCost / 2
            setUtilityRebate(amountOfUtilityRebate)

            const TotalLIHTC9 =
                amountOfTotalCost + TotalITC + amountOfMarsDepreciation + amountOfUtilityRebate + totalIRAIncentive
            setLIHTC9(TotalLIHTC9)
            const TotalLIHTC4 =
                amountOfLIHTC4 + TotalITC + amountOfMarsDepreciation + amountOfUtilityRebate + totalIRAIncentive
            setLIHTC4(TotalLIHTC4)
            const TotalNoLIHTC = 0 + TotalITC + amountOfMarsDepreciation + amountOfUtilityRebate + totalIRAIncentive
            setNoLIHTC(TotalNoLIHTC)
        }
    }, [evInput]);



    const handleSelectChange = (event) => {
        const optionValue = event.target.value
        setSelectedOption(optionValue)
    }


    const handleEvInputChange = (event) => {
        const value = event.target.value
        setEvInput(value);
        localStorage.setItem("evInput", value);


        if (value === "") {
            setEvCost(0);
            setAmountEv(0);
            setLihtc4(0);
            setItc(0);
            setmarsCost(0);
            setUtilityRebate(0);
            setIraIncentive(0);
            setLIHTC9(0);
            setLIHTC4(0);
            setNoLIHTC(0);
        } else if (value >= 0) {

            const evChargerCost = parseFloat(
                document
                    .getElementById("costOfEvChargerCell")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const evTotalCost = evInput * evChargerCost
            setEvCost(evTotalCost)

            const costOfITC =
                parseFloat(document.getElementById("costOfITCRef").textContent) /
                100
            const costOfTaxCredit =
                parseFloat(document.getElementById("taxCreditRef").textContent) /
                100

            const TotalITC = evTotalCost * costOfITC * costOfTaxCredit
            setItc(TotalITC)

            const costOfIRAIncentive = parseFloat(
                document
                    .getElementById("iraIncentive")
                    .textContent.replace("$", "")
                    .replace(",", "")
            )
            const totalIRAIncentive = evInput * costOfIRAIncentive
            setIraIncentive(totalIRAIncentive)

            const amountOfTotalCost = evTotalCost * 0.9 * 0.85
            setAmountEv(amountOfTotalCost)
            const amountOfLIHTC4 = evTotalCost * 0.4 * 0.85
            setLihtc4(amountOfLIHTC4)
            const amountOfMarsDepreciation = evTotalCost * 0.2 * 0.2
            setmarsCost(amountOfMarsDepreciation)
            const amountOfUtilityRebate = evTotalCost / 2
            setUtilityRebate(amountOfUtilityRebate)

            const TotalLIHTC9 =
                amountOfTotalCost + TotalITC + amountOfMarsDepreciation + amountOfUtilityRebate + totalIRAIncentive
            setLIHTC9(TotalLIHTC9)
            const TotalLIHTC4 =
                amountOfLIHTC4 + TotalITC + amountOfMarsDepreciation + amountOfUtilityRebate + totalIRAIncentive
            setLIHTC4(TotalLIHTC4)
            const TotalNoLIHTC = 0 + TotalITC + amountOfMarsDepreciation + amountOfUtilityRebate + totalIRAIncentive
            setNoLIHTC(TotalNoLIHTC)
        }
    }

    return (
        <div className={styles.main}>
            <InputField
                labelText="Number of chargers:"
                type="number"
                placeholder="Enter a value"
                name="evInput"
                value={evInput}
                onChange={handleEvInputChange}
                required
                validation={validateOnlyNumbers}
            />

            <div className={styles.groupContainer}>
                {/* CALCULATIONS */}
                <div className={styles.group}>
                    <h3 className={styles.heading}>
                        Calculations
                    </h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Cost of EV for Property</td>
                                <td id="amountOfEvCharger">
                                    ${evCost.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Potential Value of Benefits to Your MFAH Property */}
                <div className={styles.group}>
                    <h3 className={styles.heading}>
                        Potential Value of Benefits to Your MFAH Property*
                    </h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>At the Lower End</td>
                                <td>${(noLIHTC / 2).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            </tr>
                            <tr>
                                <td>At the Higher End</td>
                                <td>${totalLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* LIHTC */}
                <div className={styles.group}>
                    <h3 className={styles.heading}>
                        Low-Income Housing Tax Credit (LIHTC)
                    </h3>
                    <table>
                        <thead>
                            <tr>
                                <th className={styles.columnWidth}>Select Option:</th>
                                <th>
                                    <select
                                        className="form-select form-select-sm"
                                        aria-label=".form-select-sm example"
                                        onChange={handleSelectChange}
                                        value={selectedLIHTC}
                                    >
                                        <option id="9" value='9LIHTC'>
                                            9% LIHTC
                                        </option>
                                        <option id="4" value='4LIHTC'>
                                            4% LIHTC
                                        </option>
                                        <option id="0" value='NoLIHTC'>
                                            No LIHTC
                                        </option>
                                    </select>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Potential Total Value of Benefits to Your MFAH Property</td>
                                {selectedLIHTC === '9LIHTC' && (
                                    <td>
                                        ${totalLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                    </td>
                                )}
                                {selectedLIHTC === '4LIHTC' && (
                                    <td>
                                        ${totalLIHTC4.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                    </td>
                                )}
                                {selectedLIHTC === 'NoLIHTC' && (
                                    <td>
                                        ${noLIHTC.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                <table style={{ visibility: "collapse" }}>
                    <thead>
                        <tr>
                            <th>Assumptions</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cost of EV charger</td>
                            <td id="costOfEvChargerCell">
                                $2,000 per install
                            </td>
                        </tr>
                        <tr>
                            <td>Utility Rebate</td>
                            <td>$1,000 per install</td>
                        </tr>
                        <tr>
                            <td>IRA incentive from one of the programs</td>
                            <td id="iraIncentive">$1,000 per install</td>
                        </tr>
                        <tr>
                            <td>ITC for EV install</td>
                            <td id="costOfITCRef">30%</td>
                        </tr>
                        <tr>
                            <td>Value of Tax Credits</td>
                            <td id="taxCreditRef">90%</td>
                        </tr>
                        <tr>
                            <td>Value of 9% LIHTC</td>
                            <td>
                                90% of project cost, not including any
                                developer fees
                            </td>
                        </tr>
                        <tr>
                            <td>Value of 4% LIHTC</td>
                            <td>
                                40% of project cost, not including any
                                developer fees
                            </td>
                        </tr>
                        <tr>
                            <td>Value of Depreciation</td>
                            <td>
                                25% of project cost, not including any
                                developer fees
                            </td>
                        </tr>
                        <tr>
                            <td>Income Tax Rate</td>
                            <td>21% of depreciation amount</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                <table style={{ visibility: "collapse" }}>
                    <thead>
                        <tr>
                            <th>Value of</th>
                            <th>9% LIHTC</th>
                            <th>4% LIHTC</th>
                            <th>No LIHTC</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>LIHTC equity</td>
                            <td>{amountEv.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${lihtc4.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>$0</td>
                        </tr>
                        <tr>
                            <td>ITC for EV</td>
                            <td>${itc.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${itc.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${itc.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>MACRS Depreciation</td>
                            <td>${marsCost.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${marsCost.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${marsCost.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>Utility Rebate</td>
                            <td>${utilityRebate.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${utilityRebate.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${utilityRebate.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>IRA incentives</td>
                            <td>${iraIncentive.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${iraIncentive.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${iraIncentive.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>
                            <td>${totalLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${totalLIHTC4.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${noLIHTC.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                    </tbody>
                </table>

            </div>

            {queryParams.get("state") !== 'embedded' && (
                <Contact
                // selectedCase={"EV Chargers"}
                // inputs={[
                //     {
                //         'name': 'Number of Chargers:',
                //         'value': evInput
                //     }
                // ]}
                />
            )}
        </div>
    )
}

export default EV_Chargers  