import { useState } from 'react'
import styles from './ModalInputs.module.css'

const InputField = ({ type, value, onChange, placeholder, required, labelText, disabled, direction, isTextarea, ref, validation }) => {
    const [isValid, setIsValid] = useState(true)

    return (
        <div className={direction === 'column' ? styles.containerColumn : direction === 'row' ? styles.container : styles.container}>
            <label className={styles.label}>
                {labelText}
            </label>
            <div className={styles.input}>
                {isTextarea ? (
                    <textarea
                        rows='3'
                        style={{
                            'height': '100px',
                            'minHeight': '50px'
                        }}
                        className={styles.field}
                        type={type}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        required={required}
                        disabled={disabled}
                        ref={ref}
                    />
                ) : (
                    <input
                        className={isValid ? styles.field : styles.invalidField}
                        type={type}
                        value={value}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange(e)
                            if (validation) {
                                let { valid } = validation(e.target.value)

                                if (valid) {
                                    setIsValid(true)
                                }
                                else {
                                    setIsValid(false)
                                }
                            }
                            else {
                                setIsValid(e.target.validity.valid)
                            }
                        }}
                        required={required}
                        disabled={disabled}
                        ref={ref}
                        onBlur={(e) => {
                            setIsValid(e.target.validity.valid)
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default InputField