import { useEffect, useState } from "react"

import "./tabs.css"
import styles from "./tabs.module.css"

import Contact from "../../Modals/Contact"

import InputField from "../../Inputs/InputField"
import { validateOnlyNumbers } from "../../../Helpers/Validators"

const Solar_PV = () => {
    const queryParams = new URLSearchParams(window.location.search)

    const [solarInput, setSolarInput] = useState('');
    const [solarCost, setSolarCost] = useState(0);
    const [amountOfSolarProduction, setAmountOfSolarProduction] = useState(0);
    const [annualProduction, setAnnualProduction] = useState(0);
    const [taxCreditEquityLIHTC9, setTaxCreditEquityLIHTC9] = useState(0)
    const [taxCreditEquityLIHTC4, setTaxCreditEquityLIHTC4] = useState(0)
    const [itcSolar, setItcSolar] = useState(0);
    const [marsDepreciation, setMarsDepreciation] = useState(0);
    const [srec, setSrec] = useState(0);
    const [iraIncentive, setIraIncentive] = useState(0);
    const [supportableDebt, setSupportableDebt] = useState(0);
    const [totalOfLIHTC9, setTotalOfLIHTC9] = useState(0)
    const [totalOfLIHTC4, setTotalOfLIHTC4] = useState(0);
    const [totalOfNoLIHTC, setTotalOfNoLIHTC] = useState(0);
    const [selectOption, setSelectOption] = useState('9');

    useEffect(() => {
        const solarInputValue = localStorage.getItem("solarInput");
        if (solarInputValue) {
            setSolarInput(solarInputValue);
        }
    }, []);

    useEffect(() => {
        window.onbeforeunload = () => {
            localStorage.clear();
            setSolarInput(0);
            setSolarCost(0);
            setAmountOfSolarProduction(0);
            setAnnualProduction(0);
            setTaxCreditEquityLIHTC9(0);
            setTaxCreditEquityLIHTC4(0);
            setItcSolar(0);
            setMarsDepreciation(0);
            setSrec(0);
            setIraIncentive(0);
            setSupportableDebt(0);
            setTotalOfLIHTC9(0);
            setTotalOfLIHTC4(0);
            setTotalOfNoLIHTC(0);

        };
    }, []);

    useEffect(() => {
        if (solarInput === "") {

            setSolarCost(0);
            setAmountOfSolarProduction(0);
            setAnnualProduction(0);
            setTaxCreditEquityLIHTC9(0);
            setTaxCreditEquityLIHTC4(0);
            setItcSolar(0);
            setMarsDepreciation(0);
            setSrec(0);
            setIraIncentive(0);
            setSupportableDebt(0);
            setTotalOfLIHTC9(0);
            setTotalOfLIHTC4(0);
            setTotalOfNoLIHTC(0);
        } else if (solarInput >= 0) {
            const ValueOfSolarPV = parseFloat(document.getElementById("costOfSolarPV").textContent.replace("$", "").replace(",", ""));
            const ValueOfSolarNeeded = parseFloat(document.getElementById("costOfSolarNeeded").textContent.replace("$", "").replace(",", ""));
            const costOfSolarForProperty = solarInput * ValueOfSolarPV * ValueOfSolarNeeded;
            setSolarCost(costOfSolarForProperty);

            const AnnualSolarPRoductionPerUnit = parseFloat(document.getElementById("costOfAnnualSolarProduction").textContent.replace("$", "").replace(",", ""));
            const SolarProductionPossibleForProperty = solarInput * AnnualSolarPRoductionPerUnit;
            setAmountOfSolarProduction(SolarProductionPossibleForProperty)

            const UtilityRateForResidentialMeters = parseFloat(document.getElementById("costOfUtilityRateForResidential").textContent.replace("$", "").replace(",", ""));
            const ValueOfAnnualSolarProduction = SolarProductionPossibleForProperty * UtilityRateForResidentialMeters;
            setAnnualProduction(ValueOfAnnualSolarProduction)

            const TaxCredits = parseFloat(document.getElementById("costOfTaxCredits").textContent) / 100;
            const ValueOfLIHTC9 = parseFloat(document.getElementById("costOfLIHTC9").textContent) / 100;
            const TaxCreditEquityForLIHTC9 = costOfSolarForProperty * TaxCredits * ValueOfLIHTC9;
            setTaxCreditEquityLIHTC9(TaxCreditEquityForLIHTC9)

            const ValueOfLIHTC4 = parseFloat(document.getElementById("costOfLIHTC4").textContent) / 100;
            const TaxCreditEquityForLIHTC4 = costOfSolarForProperty * TaxCredits * ValueOfLIHTC4;
            setTaxCreditEquityLIHTC4(TaxCreditEquityForLIHTC4);

            const ValueOfITCForSolar = parseFloat(document.getElementById("costOfItcForSolar").textContent) / 100;
            const valueOfITC = costOfSolarForProperty * TaxCredits * ValueOfITCForSolar;
            setItcSolar(valueOfITC);

            const ValueOfDepreciation = parseFloat(document.getElementById("costOfDepreciation").textContent) / 100;
            const ValueOfIncomeTaxRate = parseFloat(document.getElementById("costOfIncomeTaxRate").textContent) / 100;
            const valueOfMarsDepreciation = costOfSolarForProperty * ValueOfDepreciation * ValueOfIncomeTaxRate;
            setMarsDepreciation(valueOfMarsDepreciation)

            const ValueOfSREC = parseFloat(document.getElementById("costOfSRECValue").textContent.replace("$", "").replace(",", ""));
            const CostOfSREC = ValueOfSREC * SolarProductionPossibleForProperty / 1000;
            setSrec(CostOfSREC)

            const ValueOfIraIncentive = parseFloat(document.getElementById("costOfIRAIncentive").textContent.replace("$", "").replace(",", ""));
            const CostOfIraIncentive = solarInput * ValueOfIraIncentive;
            setIraIncentive(CostOfIraIncentive)

            const ValueOfDSCR = parseFloat(document.getElementById("costOfDSCR").textContent.replace("$", "").replace(",", ""));
            const ValueOfDebtConstant = parseFloat(document.getElementById("costOfDebtConstant").textContent) / 100;

            const ValueOfSupportableDebt = (ValueOfAnnualSolarProduction / ValueOfDSCR) / ValueOfDebtConstant;
            setSupportableDebt(ValueOfSupportableDebt)

            const totalValueOfLIHTC9 = TaxCreditEquityForLIHTC9 + valueOfITC + valueOfMarsDepreciation + CostOfSREC + CostOfIraIncentive + ValueOfSupportableDebt;
            setTotalOfLIHTC9(totalValueOfLIHTC9);

            const totalValueOfLIHTC4 = TaxCreditEquityForLIHTC4 + valueOfITC + valueOfMarsDepreciation + CostOfSREC + CostOfIraIncentive + ValueOfSupportableDebt;
            setTotalOfLIHTC4(totalValueOfLIHTC4)

            const totalValueOfNoLIHTC = 0 + valueOfITC + valueOfMarsDepreciation + CostOfSREC + CostOfIraIncentive + ValueOfSupportableDebt;
            setTotalOfNoLIHTC(totalValueOfNoLIHTC)
        }
    }, [solarInput])

    const handleSelectorChange = (event) => {
        const optionValue = event.target.value;
        setSelectOption(optionValue);
    };

    const handleSolarInputChange = (event) => {
        const value = event.target.value;
        setSolarInput(value);

        localStorage.setItem("solarInput", value);

        if (value && value >= 0) {
            const ValueOfSolarPV = parseFloat(document.getElementById("costOfSolarPV").textContent.replace("$", "").replace(",", ""));
            const ValueOfSolarNeeded = parseFloat(document.getElementById("costOfSolarNeeded").textContent.replace("$", "").replace(",", ""));
            const costOfSolarForProperty = solarInput * ValueOfSolarPV * ValueOfSolarNeeded;
            setSolarCost(costOfSolarForProperty);

            const AnnualSolarPRoductionPerUnit = parseFloat(document.getElementById("costOfAnnualSolarProduction").textContent.replace("$", "").replace(",", ""));
            const SolarProductionPossibleForProperty = solarInput * AnnualSolarPRoductionPerUnit;
            setAmountOfSolarProduction(SolarProductionPossibleForProperty)

            const UtilityRateForResidentialMeters = parseFloat(document.getElementById("costOfUtilityRateForResidential").textContent.replace("$", "").replace(",", ""));
            const ValueOfAnnualSolarProduction = SolarProductionPossibleForProperty * UtilityRateForResidentialMeters;
            setAnnualProduction(ValueOfAnnualSolarProduction)

            const TaxCredits = parseFloat(document.getElementById("costOfTaxCredits").textContent) / 100;
            const ValueOfLIHTC9 = parseFloat(document.getElementById("costOfLIHTC9").textContent) / 100;
            const TaxCreditEquityForLIHTC9 = costOfSolarForProperty * TaxCredits * ValueOfLIHTC9;
            setTaxCreditEquityLIHTC9(TaxCreditEquityForLIHTC9)

            const ValueOfLIHTC4 = parseFloat(document.getElementById("costOfLIHTC4").textContent) / 100;
            const TaxCreditEquityForLIHTC4 = costOfSolarForProperty * TaxCredits * ValueOfLIHTC4;
            setTaxCreditEquityLIHTC4(TaxCreditEquityForLIHTC4);

            const ValueOfITCForSolar = parseFloat(document.getElementById("costOfItcForSolar").textContent) / 100;
            const valueOfITC = costOfSolarForProperty * TaxCredits * ValueOfITCForSolar;
            setItcSolar(valueOfITC);

            const ValueOfDepreciation = parseFloat(document.getElementById("costOfDepreciation").textContent) / 100;
            const ValueOfIncomeTaxRate = parseFloat(document.getElementById("costOfIncomeTaxRate").textContent) / 100;
            const valueOfMarsDepreciation = costOfSolarForProperty * ValueOfDepreciation * ValueOfIncomeTaxRate;
            setMarsDepreciation(valueOfMarsDepreciation)

            const ValueOfSREC = parseFloat(document.getElementById("costOfSRECValue").textContent.replace("$", "").replace(",", ""));
            const CostOfSREC = ValueOfSREC * SolarProductionPossibleForProperty / 1000;
            setSrec(CostOfSREC)

            const ValueOfIraIncentive = parseFloat(document.getElementById("costOfIRAIncentive").textContent.replace("$", "").replace(",", ""));
            const CostOfIraIncentive = solarInput * ValueOfIraIncentive;
            setIraIncentive(CostOfIraIncentive)

            const ValueOfDSCR = parseFloat(document.getElementById("costOfDSCR").textContent.replace("$", "").replace(",", ""));
            const ValueOfDebtConstant = parseFloat(document.getElementById("costOfDebtConstant").textContent) / 100;

            const ValueOfSupportableDebt = (ValueOfAnnualSolarProduction / ValueOfDSCR) / ValueOfDebtConstant;
            setSupportableDebt(ValueOfSupportableDebt)

            const totalValueOfLIHTC9 = TaxCreditEquityForLIHTC9 + valueOfITC + valueOfMarsDepreciation + CostOfSREC + CostOfIraIncentive + ValueOfSupportableDebt;
            setTotalOfLIHTC9(totalValueOfLIHTC9);

            const totalValueOfLIHTC4 = TaxCreditEquityForLIHTC4 + valueOfITC + valueOfMarsDepreciation + CostOfSREC + CostOfIraIncentive + ValueOfSupportableDebt;
            setTotalOfLIHTC4(totalValueOfLIHTC4)

            const totalValueOfNoLIHTC = 0 + valueOfITC + valueOfMarsDepreciation + CostOfSREC + CostOfIraIncentive + ValueOfSupportableDebt;
            setTotalOfNoLIHTC(totalValueOfNoLIHTC)

        } else {

            setSolarCost(0);
            setAmountOfSolarProduction(0);
            setAnnualProduction(0);
            setTaxCreditEquityLIHTC9(0);
            setTaxCreditEquityLIHTC4(0);
            setItcSolar(0);
            setMarsDepreciation(0);
            setSrec(0);
            setIraIncentive(0);
            setSupportableDebt(0);
            setTotalOfLIHTC9(0);
            setTotalOfLIHTC4(0);
            setTotalOfNoLIHTC(0);

        }
    };

    return (
        <div className={styles.main}>
            <div>
                <InputField
                    labelText="Number of Apartments on Property:"
                    type={"number"}
                    placeholder="Enter a value"
                    value={solarInput}
                    onChange={handleSolarInputChange}
                    required
                    validation={validateOnlyNumbers}
                />
            </div>


            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                <table style={{ visibility: 'collapse' }} >
                    <thead>
                        <tr>
                            <th>Assumptions</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cost of Solar PV</td>
                            <td id="costOfSolarPV">$3,000 per install</td>
                        </tr>
                        <tr>
                            <td>Solar Needed per Unit</td>
                            <td id='costOfSolarNeeded'>3.00 kW</td>
                        </tr>
                        <tr>
                            <td>Annual Solar Production Per Unit</td>
                            <td id='costOfAnnualSolarProduction'>4000 kWh</td>
                        </tr>
                        <tr>
                            <td>Utility Rate for Residential Meters</td>
                            <td id='costOfUtilityRateForResidential'>$0.12 per kWh</td>
                        </tr>
                        <tr>
                            <td>SREC Value</td>
                            <td id='costOfSRECValue'>$10 per MWh</td>
                        </tr>
                        <tr>
                            <td>IRA incentive from one of the programs</td>
                            <td id='costOfIRAIncentive'>$1,000 per Unit</td>
                        </tr>
                        <tr>
                            <td>ITC for Solar</td>
                            <td id='costOfItcForSolar'>40%</td>
                        </tr>
                        <tr>
                            <td>Value of Tax Credits</td>
                            <td id='costOfTaxCredits'>90%</td>
                        </tr>
                        <tr>
                            <td>Value of 9% LIHTC</td>
                            <td id='costOfLIHTC9'>90%</td>
                        </tr>
                        <tr>
                            <td>Value of 4% LIHTC</td>
                            <td id='costOfLIHTC4'>40%</td>
                        </tr>
                        <tr>
                            <td>Value of Depreciation</td>
                            <td id='costOfDepreciation'>25%</td>
                        </tr>
                        <tr>
                            <td>Income Tax Rate</td>
                            <td id='costOfIncomeTaxRate'>21%</td>
                        </tr>
                        <tr>
                            <td>DSCR</td>
                            <td id='costOfDSCR'>1.15</td>
                        </tr>
                        <tr>
                            <td>Debt Constant</td>
                            <td id='costOfDebtConstant'>6.50%</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div className={styles.groupContainer}>
                {/* CALCULATIONS */}
                <div className={styles.group}>
                    <h3 className={styles.heading}>
                        Calculations
                    </h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Cost of Solar for Property</td>
                                <td>${solarCost.toLocaleString(undefined, { maximumFractionDigits: 0 })} </td>
                            </tr>
                            <tr>
                                <td>Solar Production Possible for Property</td>
                                <td>{amountOfSolarProduction.toLocaleString(undefined, { maximumFractionDigits: 0 })} kWh</td>
                            </tr>
                            <tr>
                                <td>Value of Annual Solar Production</td>
                                <td>${annualProduction.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Potential Value of Benefits to Your MFAH Property* */}
                <div className={styles.group}>
                    <h3 className={styles.heading}>
                        Potential Value of Benefits to Your MFAH Property*
                    </h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>At the Lower End</td>
                                <td>${(totalOfNoLIHTC / 2).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            </tr>
                            <tr>
                                <td>At the Higher End</td>
                                <td>${totalOfLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* LIHTC */}
                <div className={styles.group}>
                    <h3 className={styles.heading}>
                        Low-Income Housing Tax Credit (LIHTC)
                    </h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Select Option:</th>
                                <th>
                                    <select
                                        className="form-select form-select-sm"
                                        aria-label=".form-select-sm example"
                                        onChange={handleSelectorChange}
                                        value={selectOption || ''}
                                    >
                                        <option id="9" value="9">
                                            9% LIHTC
                                        </option>
                                        <option id="4" value="4">
                                            4% LIHTC
                                        </option>
                                        <option id="0" value="0">
                                            No LIHTC
                                        </option>
                                    </select>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Potential Total Value of Benefits to Your MFAH Property</td>
                                {selectOption === '9' && (
                                    <td>
                                        ${totalOfLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                    </td>
                                )}
                                {selectOption === '4' && (
                                    <td>
                                        ${totalOfLIHTC4.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                    </td>
                                )}
                                {selectOption === '0' && (
                                    <td>
                                        ${totalOfNoLIHTC.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            {/* HIDDEN FOR CALCULATIONS */}
            <div className={styles.hidden}>
                <table style={{ visibility: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>Value of</th>
                            <th>9% LIHTC</th>
                            <th>4% LIHTC</th>
                            <th>No LIHTC</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td >	Tax Credit Equity </td>
                            <td >${taxCreditEquityLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>

                            <td>${taxCreditEquityLIHTC4.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>$0</td>
                        </tr>
                        <tr>
                            <td>ITC</td>
                            <td>${itcSolar.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${itcSolar.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${itcSolar.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>MACRS Depreciation</td>
                            <td>${marsDepreciation.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${marsDepreciation.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${marsDepreciation.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>SREC </td>
                            <td>${srec.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${srec.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${srec.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>IRA incentives</td>
                            <td>${iraIncentive.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${iraIncentive.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${iraIncentive.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>Supportable Debt</td>
                            <td>${supportableDebt.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${supportableDebt.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${supportableDebt.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>
                            <td>${totalOfLIHTC9.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${totalOfLIHTC4.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                            <td>${totalOfNoLIHTC.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {queryParams.get("state") !== 'embedded' && (
                <Contact
                // selectedCase={"Solar PV"}
                // inputs={[
                //     {
                //         'name': 'Number of Apartments on Property:',
                //         'value': solarInput
                //     }
                // ]}
                />
            )}
        </div>
    )
}


export default Solar_PV
